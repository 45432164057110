.outer{
    height: 90vh;
    display: grid;
    place-items:center ;
}
.content {
    width: 330px;
    padding: 40px 30px;
    background: #dde1e7;
    border-radius: 10px;
    box-shadow: -3px -3px 7px #2c2b2b73,
                 2px 2px 5px rgba(94,104,121,0.288);
  }
  
  .content .text {
    font-size: 33px;
    font-weight: 600;
    margin-bottom: 35px;
    color: #595959;
  }
  
  .field {
    height: 50px;
    width: 100%;
    display: flex;
    position: relative;
  }
  
  .field:nth-child(2) {
    margin-top: 20px;
  }
  
  .field .input {
    height: 100%;
    width: 100%;
    padding-left: 45px;
    outline: none;
    border: none;
    font-size: 18px;
    background: #dde1e7;
    color: #595959;
    border-radius: 25px;
    box-shadow: inset 2px 2px 5px #aaaebd,
                inset -5px -5px 10px #ffffff73;
  }
  
  .field .input:focus {
    box-shadow: inset 1px 1px 2px #BABECC,
                inset -1px -1px 2px #d3d0d073;
  }
  
  .field .span {
    position: absolute;
    color: #595959;
    width: 50px;
    line-height: 55px;
  }
  
  .field .label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 45px;
    pointer-events: none;
    color: #666666;
  }
  
  .field .input:valid ~ label {
    opacity: 0;
  }
  
  .forgot-pass {
    text-align: left;
    margin: 10px 0 10px 5px;
  }
  
  .forgot-pass a {
    font-size: 16px;
    color: #666666;
    text-decoration: none;
  }
  
  .forgot-pass:hover a {
    text-decoration: underline;
  }
  
  .button {
    margin: 15px 0;
    width: 100%;
    height: 50px;
    font-size: 18px;
    line-height: 50px;
    font-weight: 600;
    background: #dde1e7;
    border-radius: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    color: #333232;
    box-shadow: 2px 2px 5px #BABECC,
               -5px -5px 10px #b3b2b273;
  }
  
  .button:focus {
    color: #3498db;
    box-shadow: inset 2px 2px 5px #BABECC,
               inset -5px -5px 10px #ffffff73;
  }
  
  .sign-up {
    margin: 10px 0;
    color: #595959;
    font-size: 16px;
  }
  
  .sign-up a {
    color: #3498db;
    text-decoration: none;
  }
  
  .sign-up a:hover {
    text-decoration: underline;
  }
  /* === removing default button style ===*/
.buttonT {
  margin: 0;
  height: auto;
  background: transparent;
  padding: 0;
  border: none;
}

/* button styling */
.buttonT {
  --border-right: 6px;
  --text-stroke-color: rgba(255,255,255,0.6);
  --animation-color: #D03232;
  --fs-size: 2em;
  letter-spacing: 3px;
  text-decoration: none;
  font-size: var(--fs-size);
  font-family: "Arial";
  position: relative;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: 1px var(--text-stroke-color);
}
/* this is the text, when you hover on button */
.hover-text {
  position: absolute;
  box-sizing: border-box;
  content: attr(data-text);
  color: var(--animation-color);
  width: 0%;
  inset: 0;
  border-right: var(--border-right) solid var(--animation-color);
  overflow: hidden;
  transition: 0.5s;
  -webkit-text-stroke: 1px var(--animation-color);
  
}
/* hover */
.buttonT:hover .hover-text {
  width: 100%;
  filter: drop-shadow(0 0 23px var(--animation-color))
}