body{
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  height: 100vh;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url('https://www.phantom2me.com/wp-content/uploads/2018/10/slider_1-new-2.png');
  background-repeat: no-repeat;
background-attachment: fixed;
background-size: cover; */
background-color: #012f3c;
overflow-x: hidden;
}

.pulse {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(168, 228, 252, 0.7);
  }
  
  70% {
    transform: scale(1.03);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
.card {
  width: 30%;
  height: 150px;
  min-width: 300px;
  background: rgba(0,0,0,0.4);
  transition: .2s linear;
  box-shadow: 1px 1px 4px lightslategray;
  cursor: default;
  margin-right: 8px;
  margin-bottom: 9px;
  /* border-color: transparent; */
  font-weight: 600;
  font-size: 18px;
}
nav{
  z-index: 2;
  box-shadow: 0 0 5px 0 ;
      background: inherit;
      backdrop-filter: blur(10px);
}

.card-top-part {
  width: 100%;
  height: 70%;
  display: flex;
}
.role{
  font-size: 12px !important;
}
.bg-orange{
  background-color: #e76800;
}
.left-part {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}



.user-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.name {
  font-size: 1.5em;
}

.role {
  font-size: 10px;
  color: lightcyan;
  font-weight: bold;
  text-transform: lowercase;
  
  border-radius: 15px;
}

.position {
  color: grey;
}

.card-bottom-part {
  width: 100%;
  height: 30%;
  display: flex;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

footer{
  /* box-shadow: 0 0 4px 0 ;
      background: inherit;
      backdrop-filter: blur(4px); */
      background-color: rgba(0, 0, 0, 0.24);  
      backdrop-filter: blur(5px);
      box-shadow: 0 0 4px 0
    }

.bottom-part {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.bottom-part:hover > .link {
  transform: scale(1.1);
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  transition: .2s linear;
}

.icon {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}


.open {
  position: fixed;
  top: 2px;
  left: 10px;
  width: 50px;
  height: 50px;
  display: block;
  cursor: pointer;
  transition: opacity 0.2s linear;
}
.open:hover {
  opacity: 1;
}
.open span {
  display: block;
  float: right;
  clear: both;
  height: 4px;
  width: 40px;
  border-radius: 40px;
  background-color: #fff;
  position: absolute;
  right: 3px;
  top: 3px;
  overflow: hidden;
  transition: all 0.4s ease;
}
.open span:nth-child(1) {
  margin-top: 15px;
  z-index: 9;
}
.open span:nth-child(2) {
  margin-top: 25px;
}
.open span:nth-child(3) {
  margin-top: 35px;
}
.sub-menu {
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  height: 0;
  width: 0;
  right: -10px;
  top: 10px;
  position: absolute;
  background-color:#01323f93 ;
  z-index: 18;
  overflow: hidden;
}
.sub-menu li {
  display: block;
  float: left;
  clear: both;
  height: auto;
  margin-left: -160px;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.sub-menu li:first-child {
  margin-top: 180px;
}
.sub-menu li:nth-child(1) {
  -webkit-transition-delay: 0.05s;
}
.sub-menu li:nth-child(2) {
  -webkit-transition-delay: 0.1s;
}
.sub-menu li:nth-child(3) {
  -webkit-transition-delay: 0.15s;
}
.sub-menu li:nth-child(4) {
  -webkit-transition-delay: 0.2s;
}
.sub-menu li:nth-child(5) {
  -webkit-transition-delay: 0.25s;
}
.sub-menu li a {
  color: #fff;
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-size: 16px;
  width: 100%;
  display: block;
  float: left;
  line-height: 40px;
}
.oppenned .sub-menu {
  opacity: 1;
  height: 750px;
  border-radius: 15px;
  width: 300px;
}
.oppenned span:nth-child(2) {
  overflow: visible;
}
.oppenned span:nth-child(1), .oppenned span:nth-child(3) {
  z-index: 100;
  transform: rotate(45deg);
}
.oppenned span:nth-child(1) {
  transform: rotate(45deg) translateY(5px) translateX(5px);
}
.oppenned span:nth-child(2) {
  height: 800px;
  width: 300px;
  left: -67px;
  top: -160px;
  background-color: #01323f;
}
.oppenned span:nth-child(3) {
  transform: rotate(-45deg) translateY(-10px) translateX(10px);
}
.oppenned li {
  margin-left: 80px;
}
a{
  text-decoration: none;
}
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
.loader {
  height: 80vh;
  display: grid;
  place-content: center;
}

.wheel {
  fill: rgb(0, 0, 0);
  font-size: 100px;
  width: 100px;
  height: 100px;
  border-radius: 6em;
  text-align: center;
  animation: rolling 1s linear infinite;
}

#rim {
  fill: rgb(0, 0, 0);
}

#tire {
  fill: rgb(0, 0, 0);
}

.road {
  margin-top: -4px;
  width: 500%;
  height: 10px;
  border-radius: 5em;
  background-color: rgb(0, 0, 0);
  animation: road-move 2s linear infinite;
}

@keyframes rolling {
  to {
    transform: rotate(-360deg);
  }
}

@keyframes road-move {
  from {
    transform: translateX(-140%);
  }

  to {
    transform: translateX(100%);
  }
}
